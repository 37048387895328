import { ApiMessage, MessageType } from './ApiMessage';

/**
 * @since 3.0
 */
export class SuccessResponse<T> {
  public readonly ok = true;
  public readonly data: T;

  constructor(data: T) {
    this.data = data;
  }
}

/**
 * @since 3.0
 */
export class ErrorResponse {
  public readonly ok = false;
  public readonly statusCode: number;
  public readonly statusText: string;
  public readonly data?: any;

  constructor(statusCode: number, statusText: string, data: any = undefined) {
    this.statusCode = statusCode;
    this.statusText = statusText;
    this.data = data;
  }
}

/**
 * Message send by the runtime to the renderer to transfer the lazy fetch result
 * @since 3.0
 */
export class FetchDataResponseMessage<DATA> extends ApiMessage<SuccessResponse<DATA> | ErrorResponse> {
  readonly type = MessageType.fetchDataResponse;
  readonly correlationId: number;

  constructor(correlationId: number, response: SuccessResponse<DATA> | ErrorResponse) {
    super(response);
    this.correlationId = correlationId;
  }
}

export default FetchDataResponseMessage;
