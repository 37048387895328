import { ResponseError } from '../api/runtime-api';

const DEFAULT_REQUEST_TIMEOUT = 30 * 1000;

interface PromiseExecutor<DATA> {
  resolve: (response: DATA) => void;
  reject: (reason: ResponseError<any>) => void;
  timeout: number;
}

class MessageHandler<DATA> {
  #responseResolver: { [correlationId: string]: PromiseExecutor<DATA> } = {};
  #correlationIdCounter = 0;
  #_requestTimeout = DEFAULT_REQUEST_TIMEOUT;

  public sendRequest = (process: (correlationId: number) => void): Promise<DATA> => {
    const correlationId = this.#correlationIdCounter++;
    return new Promise<DATA>((resolve, reject) => {
      const timeout = window.setTimeout(() => this.rejectResponse(correlationId, {
        statusCode: 408,
        statusText: 'RequestTimeout'
      }), this.#_requestTimeout);
      this.#responseResolver[correlationId] = { resolve, reject, timeout };
      process(correlationId);
    });
  };

  public rejectResponse = (correlationId: number, error: ResponseError<any>) => {
    const resolver = this.removeResolver(correlationId);
    resolver?.reject(error);
  };

  public resolveResponse = (correlationId: number, data: DATA) => {
    const resolver = this.removeResolver(correlationId);
    resolver?.resolve(data);
  };

  public set requestTimeout(value: number | undefined) {
    this.#_requestTimeout = value ?? DEFAULT_REQUEST_TIMEOUT;
  }

  private removeResolver(correlationId: number) {
    const resolver = this.#responseResolver[correlationId];
    if (resolver) {
      delete this.#responseResolver[correlationId];
      clearTimeout(resolver.timeout);
    }
    return resolver;
  }
}

export default MessageHandler;
