import { StandardLonghandProperties } from 'csstype';
import { ApiMessage, MessageType } from './ApiMessage';
import { ScreenMode } from './ScreenMode';

export type Position = { mode: ScreenMode } & Pick<StandardLonghandProperties, 'top' | 'left' | 'width' | 'height'>

/**
 * Message to update the relative screen position of the renderer.
 * @since 3.0
 */
export class UpdatePositionMessage extends ApiMessage<Position> {
  readonly type = MessageType.position;

  constructor(position: Position) {
    super(position);
  }
}

export default UpdatePositionMessage;
