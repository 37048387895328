/**
 * Message-types used by the postMessage API
 * @since 3.0
 */
export enum MessageType {
  /**
   * @since 3.0
   */
  bootstrap = 'bootstrap',
  /**
   * @since 3.0
   */
  updateConfiguration = 'updateConfiguration',
  /**
   * @since 3.0
   */
  screenMode = 'screenMode',
  /**
   * @since 3.0
   */
  selectionChanged = 'selectionChanged',
  /**
   * @since 3.0
   */
  fetchDataRequest = 'fetchDataRequest',
  /**
   * @since 3.0
   */
  fetchDataResponse = 'fetchDataResponse',
  /**
   * @since 3.0
   */
  position = 'position',
  /**
   * @since 3.14
   */
  csvExportRequest = 'csvExportRequest',
  /**
   * @since 3.14
   */
  csvExportResponse = 'csvExportResponse',
  /**
   * @since 3.14
   */
  updateRendererFeatures = 'updateRendererFeatures',
}

/**
 * Base Message
 * @since 3.0
 */
export abstract class ApiMessage<T> {
  /**
   * the message type
   * @since 3.0
   */
  readonly abstract type: MessageType;
  /**
   * the payload
   * @since 3.0
   */
  payload: Readonly<T>;

  protected constructor(payload: Readonly<T>) {
    this.payload = payload;
  }
}

export default ApiMessage;
