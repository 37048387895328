import { AtRule, Properties } from 'csstype';
import { ApiMessage, MessageType } from './ApiMessage';

export type PaletteType = 'light' | 'dark';

export interface SimplePaletteColorOptions {
  light?: string;
  main: string;
  dark?: string;
  contrastText?: string;
}

export type PaletteColorOptions = SimplePaletteColorOptions | ColorPartial;

export type ColorPartial = Partial<Color>;

export interface Color {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  A100: string;
  A200: string;
  A400: string;
  A700: string;
}

export interface CommonColors {
  black: string;
  white: string;
}

export interface TypeText {
  primary: string;
  secondary: string;
  disabled: string;
  hint: string;
}

export interface TypeAction {
  active: string;
  hover: string;
  hoverOpacity: number;
  selected: string;
  disabled: string;
  disabledBackground: string;
}

export interface PaletteOptions {
  type?: PaletteType;

  primary?: PaletteColorOptions;
  secondary?: PaletteColorOptions;
  warning: PaletteColorOptions;
  error?: PaletteColorOptions;

  tonalOffset?: number;
  contrastThreshold?: number;

  common?: Partial<CommonColors>;
  grey?: ColorPartial;
  text?: Partial<TypeText>;
  divider?: string;
  action?: Partial<TypeAction>;
  background?: Partial<TypeBackground>;
  getContrastText?: (background: string) => string;
}


export interface FontStyle
  extends Required<{
    fontFamily: CSSProperties['fontFamily'];
    fontSize: number;
    fontWeightLight: CSSProperties['fontWeight'];
    fontWeightRegular: CSSProperties['fontWeight'];
    fontWeightMedium: CSSProperties['fontWeight'];
    fontWeightBold: CSSProperties['fontWeight'];
  }> {
}

export interface FontStyleOptions extends Partial<FontStyle> {
  htmlFontSize?: number;
  allVariants?: CSSProperties;
}

export interface TypographyOptions
  extends Partial<Record<ThemeStyle, TypographyStyleOptions> & FontStyleOptions> {
}

export interface TypographyStyleOptions extends Partial<TypographyStyle> {
}

export type TypographyStyle =
  Required<Pick<CSSProperties, 'fontFamily' | 'fontSize' | 'fontWeight' | 'fontStyle' | 'color'>>
  &
  Partial<Pick<CSSProperties, 'letterSpacing' | 'lineHeight' | 'textTransform'>>;

type JSSFontface = AtRule.FontFace & { fallbacks?: AtRule.FontFace[] };

/**
 * Allows the user to augment the properties available
 */
export interface BaseCSSProperties extends Properties<number | string> {
  '@font-face'?: JSSFontface | JSSFontface[];
}

export interface CSSProperties extends BaseCSSProperties {
  // Allow pseudo selectors and media queries
  [k: string]: BaseCSSProperties[keyof BaseCSSProperties] | CSSProperties;
}

export type ThemeStyle =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

export interface TypeBackground {
  default: string;
  paper: string;
}

export interface Theme {
  palette?: PaletteOptions;
  typography?: TypographyOptions;
}

export interface BootstrapConfiguration<CONFIG> {
  config: CONFIG;
  theme: Theme;
  requestTimeout?: number;
  stylesheets?: string[];
  locale: string;
}

/**
 * message send to init the renderer
 * @since 3.0
 */
export class BootstrapMessage<CONFIG> extends ApiMessage<BootstrapConfiguration<CONFIG>> {
  readonly type = MessageType.bootstrap;

  constructor(payload: BootstrapConfiguration<CONFIG>) {
    super(payload);
  }
}

export default BootstrapMessage;
