import { ApiMessage, MessageType } from './ApiMessage';

/**
 * message send from the runtime to the renderer to trigger an csv export
 * @since 3.14
 */
export class CsvExportRequestMessage extends ApiMessage<undefined> {
  readonly type = MessageType.csvExportRequest;
  readonly correlationId: number;

  constructor(correlationId: number) {
    super(undefined);
    this.correlationId = correlationId;
  }
}

export default CsvExportRequestMessage;
