/**
 * screen mode
 * @since 3.0
 */
export enum ScreenMode {
  /**
   * Modal dialog mode
   * @since 3.0
   */
  modal = 'modal',

  /**
   * fullscreen without modal dialog
   * @since 3.0
   */
  fullscreen = 'fullscreen',

  /**
   * normal widget size mode
   * @since 3.0
   */
  normal = 'normal',
}
