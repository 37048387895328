import { ApiMessage, MessageType } from './ApiMessage';

/**
 * Message to update the renderer's config
 * @since 3.0
 */
export class UpdateConfigurationMessage<CONFIG> extends ApiMessage<CONFIG> {
  readonly type = MessageType.updateConfiguration;

  constructor(payload: CONFIG) {
    super(payload);
  }
}

export default UpdateConfigurationMessage;
