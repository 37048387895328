import React, { ReactEventHandler, useEffect, useState } from 'react';
import { MessageType } from '../../api/messages/ApiMessage';
import BootstrapMessage, { BootstrapConfiguration } from '../../api/messages/BootstrapMessage';
import CsvExportRequestMessage from '../../api/messages/CsvExportRequestMessage';
import CsvExportResponseMessage from '../../api/messages/CsvExportResponseMessage';
import FetchDataRequestMessage from '../../api/messages/FetchDataRequestMessage';

import { ErrorResponse, FetchDataResponseMessage, SuccessResponse } from '../../api/messages/FetchDataResponseMessage';
import { ScreenMode } from '../../api/messages/ScreenMode';
import SelectionChangedMessage from '../../api/messages/SelectionChangedMessage';
import UpdateConfigurationMessage from '../../api/messages/UpdateConfigurationMessage';
import UpdatePositionMessage, { Position } from '../../api/messages/UpdatePositionMessage';
import UpdateRendererFeatures from '../../api/messages/UpdateRendererFeatures';
import UpdateScreenModeMessage from '../../api/messages/UpdateScreenModeMessage';
import RendererApi, { CsvData, Features } from '../../api/renderer-api';
import MessageHandler from '../../utils/MessageHandler';

// private
/**
 * API to interact with the renderer via postMessages.
 */
class RendererApiImpl<CONFIG> implements RendererApi<CONFIG> {
  readonly contentWindow: WindowProxy;
  private readonly messageHandler = new MessageHandler<CsvData | null>();

  constructor(contentWindow: Window) {
    this.contentWindow = contentWindow;
  }

  bootstrap(config: BootstrapConfiguration<CONFIG>): void {
    this.contentWindow.postMessage(new BootstrapMessage<CONFIG>(config), '*');
  }

  updateConfiguration(config: CONFIG): void {
    this.contentWindow.postMessage(new UpdateConfigurationMessage<CONFIG>(config), '*');
  }

  setElementPosition(position: Position): void {
    this.contentWindow.postMessage(new UpdatePositionMessage(position), '*');
  }

  getCSVData(): Promise<CsvData | null> {
    return this.messageHandler.sendRequest(correlationId => {
      this.contentWindow.postMessage(new CsvExportRequestMessage(correlationId), '*');
    })
  }

  resolveCsvResponse = (correlationId: number, data: CsvData | null) => {
    this.messageHandler.resolveResponse(correlationId, data);
  };

}

interface Props<CONFIG, SELECTION, LAZY_REQUEST, LAZY_RESPONSE> extends BootstrapConfiguration<CONFIG> {
  title: string;
  entryPoint: string;
  onSelectionChanged: (selection: SELECTION) => void;
  onScreenStateChanged?: (fullscreen: boolean) => void;
  onCsvExportTriggerChanged?: (newCallback: (() => Promise<CsvData | null>) | null) => void;
  fetchData: (state: LAZY_REQUEST) => Promise<LAZY_RESPONSE>;
}

/**
 * A default implementation of a renderer
 * @since 3.0
 */
const Renderer = <CONFIG extends {}, SELECTION extends {}, LAZY_REQUEST = {}, LAZY_RESPONSE = {}>({
  title,
  entryPoint,
  onSelectionChanged,
  onScreenStateChanged,
  onCsvExportTriggerChanged,
  fetchData,
  config,
  theme,
  stylesheets,
  locale,
}: Props<CONFIG, SELECTION, LAZY_REQUEST, LAZY_RESPONSE>) => {
  type WidgetToRuntimeData =
    | UpdateScreenModeMessage
    | SelectionChangedMessage<SELECTION>
    | FetchDataRequestMessage<LAZY_REQUEST>
    | UpdateRendererFeatures
    | CsvExportResponseMessage;
  const [screenMode, setScreenMode] = useState<ScreenMode>(ScreenMode.normal);
  const [{ supportCsvExport = false }, setSupportedFeatures] = useState<Features>({ supportCsvExport: false });
  const [selection, setSelection] = useState<SELECTION | undefined>(undefined);
  const [api, setApi] = useState<RendererApiImpl<CONFIG> | null>(null);
  const [iframe, setIframe] = useState<HTMLIFrameElement | null>(null);
  const fullScreen = screenMode !== ScreenMode.normal;

  useEffect(() => {
    if (api && onCsvExportTriggerChanged && supportCsvExport) {
      onCsvExportTriggerChanged(api.getCSVData.bind(api));
    }
  }, [api, onCsvExportTriggerChanged, supportCsvExport]);
  useEffect(() => api?.updateConfiguration(config), [api, config]);
  useEffect(() => onScreenStateChanged?.(fullScreen), [onScreenStateChanged, fullScreen]);
  useEffect(() => {
    if (selection !== undefined) {
      setSelection(undefined); // reset to prevent infinite loop
      onSelectionChanged(selection);
    }
  }, [onSelectionChanged, selection]);

  const widgetLoaded: ReactEventHandler<HTMLIFrameElement> = (e) => {
    let iframe = e.target as HTMLIFrameElement;
    const parentElement = iframe.parentElement;
    if (!parentElement) {
      return;
    }
    const contentWindow = iframe.contentWindow;
    if (!contentWindow) {
      return;
    }
    const rendererApiImpl = new RendererApiImpl<CONFIG>(contentWindow);
    rendererApiImpl.bootstrap({ config, theme, stylesheets, locale });
    setIframe(iframe);
    setApi(rendererApiImpl);
  };

  useEffect(() => {
    const parentElement = iframe?.parentElement;
    if (!parentElement || !api) {
      return;
    }
    let positionHandler: (() => void) | null = null;
    if (screenMode !== ScreenMode.normal) {
      const { width, height } = parentElement.getBoundingClientRect();
      positionHandler = () => {
        const viewportOffset = parentElement.getBoundingClientRect();
        api.setElementPosition({
          top: viewportOffset.top + parentElement.clientTop + 'px',
          left: viewportOffset.left + parentElement.clientLeft + 'px',
          width: width + 'px',
          height: height + 'px',
          mode: screenMode,
        });
      };
      positionHandler();
      window.addEventListener('scroll', positionHandler);
      window.addEventListener('resize', positionHandler);
    } else {
      api.setElementPosition({ top: 0, left: 0, mode: screenMode });
    }
    return () => {
      if (positionHandler) {
        window.removeEventListener('scroll', positionHandler);
        window.removeEventListener('resize', positionHandler);
      }
    };
  }, [screenMode, iframe, api]);

  useEffect(() => {
    const contentWindow = iframe?.contentWindow;
    if (!contentWindow) {
      return;
    }
    const messageEventListener = async (evt: MessageEvent) => {
      if (evt.source !== contentWindow) {
        return;
      }
      const data = evt.data as WidgetToRuntimeData;
      switch (data.type) {
      case MessageType.screenMode:
        setScreenMode(data.payload);
        break;
      case MessageType.selectionChanged:
        setSelection(data.payload);
        break;
      case MessageType.fetchDataRequest:
        let response: SuccessResponse<LAZY_RESPONSE> | ErrorResponse;
        try {
          response = new SuccessResponse(await fetchData(data.payload));
        } catch (e: any) {
          if (e.statusCode !== undefined && e.statusText !== undefined) {
            response = new ErrorResponse(e.statusCode, e.statusText, e.data);
          } else {
            response = new ErrorResponse(500, e);
          }
        }
        contentWindow.postMessage(new FetchDataResponseMessage<LAZY_RESPONSE>(data.correlationId, response), '*');
        break;
      case MessageType.updateRendererFeatures:
        setSupportedFeatures(data.payload);
        break;
      case MessageType.csvExportResponse:
        api?.resolveCsvResponse(data.correlationId, data.payload ?? null);
        break;
      }
    };
    window.addEventListener('message', messageEventListener);
    return () => window.removeEventListener('message', messageEventListener);
  }, [iframe, setScreenMode, setSelection, setSupportedFeatures, fetchData, api]);

  return (
    <div style={{
      width: '100%',
      height: '100%',
    }}>
      <iframe
        title={title}
        src={entryPoint}
        onLoad={widgetLoaded}
        style={{
          width: '100%',
          height: '100%',
          border: 0,
          ...(fullScreen
            ? {
              top: 0,
              left: 0,
              position: 'fixed',
              zIndex: 9999,
            }
            : {}),
        }}
      />
    </div>
  );
};

export default Renderer;
